import React, { Component } from 'react';
import $ from 'jquery';
import HeaderFade from './HeaderFade'
import Footer from './Footer'

class Home extends Component {

  componentDidMount() {
    document.querySelector('body').scrollIntoView({
      block: "start"
    });
  }

  render() {
    return (
      <div>
        <HeaderFade />
        <div id="carouselExampleSlidesOnly" className="carousel slide" data-bs-ride="carousel" data-bs-interval="5000">
          <div className="carousel-inner">
            <div className="carousel-item carousel-image bg-img-1 active"></div>
            {/* <div className="carousel-item carousel-image bg-img-2 active"></div> */}
            <div className="carousel-item carousel-image bg-img-3"></div>
          </div>
        </div>
        <Footer />
      </div >
    );
  }
}

export default Home;
