import React, { Component } from 'react';
import Header from './HeaderStatic';
import Footer from './Footer';
import $ from 'jquery';

class Team extends Component {

    constructor(props) {
        super(props);
        this.state = {
            anthonyJones: true,
            nigelJones: false,
            duaneBrighton: false,
            simonKirk: false,
            pabloGarrido: false,
            ellaDudko: false,
            mingYuiCheung: false,
            laurenEphron: false
        }
    }

    componentDidMount() {
        document.querySelector('body').scrollIntoView({
            block: "start"
        })
    }

    onClick = (name) => {
        let newState = {
            anthonyJones: false,
            nigelJones: false,
            andreasLeonardi: false,
            simonKirk: false,
            pabloGarrido: false,
            ellaDudko: false,
            mingYuiCheung: false,
            laurenEphron: false,
            garyDay: false,
            binhAnDuong: false,
            abhishekChaudhary: false,
            meandiNiemand: false
        }
        newState[name] = true;
        this.setState(() => ({ ...newState }))
    }

    render() {
        const { anthonyJones, nigelJones, andreasLeonardi, simonKirk, pabloGarrido, ellaDudko, mingYuiCheung, laurenEphron, garyDay, binhAnDuong, abhishekChaudhary, meandiNiemand } = this.state;
        return (
            <div>
                {
                    <div>
                        <Header banner={"/images/headers/10.jpg"} />
                        <div className="container-fluid headerline d-none d-md-block">
                            <div className="container">
                                <div className="row p-0 m-0">
                                    <div className="col-7 p-0 m-0">
                                        <div className="row mt-5">
                                            <div className="col-6 d-flex justify-content-end p-1" onMouseEnter={() => this.onClick('anthonyJones')}>
                                                <div className="namebox d-flex align-items-center justify-content-center text-center"><p className="txt-large p-0 m-0">Anthony Jones<br></br><span className="txt-small m-0 p-0">Director</span></p></div>
                                            </div>
                                            <div className="col-6 pb-1 d-flex justify-content-start p-1" onMouseEnter={() => this.onClick('nigelJones')}>
                                                <div className="namebox d-flex align-items-center justify-content-center text-center"><p className="txt-large p-0 m-0">Nigel Jones<br></br><span className="txt-small">Director</span></p></div>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-3 p-1 d-flex justify-content-center" onMouseEnter={() => this.onClick('laurenEphron')}>
                                                <div className="namebox d-flex align-items-center justify-content-center text-center"><p className="txt-large p-0 m-0">Lauren Ephron<br></br><span className="txt-small">Accounts and Administration</span></p></div>
                                            </div>
                                            <div className="col-3 p-1 d-flex justify-content-center" onMouseEnter={() => this.onClick('pabloGarrido')}>
                                                <div className="namebox d-flex align-items-center justify-content-center text-center"><p className="txt-large p-0 m-0">Pablo Garrido<br></br><span className="txt-small">Engineering & Estimating Manager</span></p></div>
                                            </div>
                                            <div className="col-3 p-1 d-flex justify-content-center" onMouseEnter={() => this.onClick('simonKirk')}>
                                                <div className="namebox d-flex align-items-center justify-content-center text-center"><p className="txt-large p-0 m-0">Simon Kirk<br></br><span className="txt-small">Operations Manager</span></p></div>
                                            </div>
                                            <div className="col-3 p-1 d-flex justify-content-center" onMouseEnter={() => this.onClick('mingYuiCheung')}>
                                                <div className="namebox d-flex align-items-center justify-content-center text-center"><p className="txt-large p-0 m-0">Ming Yui Cheung<br></br><span className="txt-small">Design Manager</span></p></div>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-3 p-1 d-flex justify-content-center" onMouseEnter={() => this.onClick('ellaDudko')}>
                                                <div className="namebox d-flex align-items-center justify-content-center text-center"><p className="txt-large p-0 m-0">Ella Dudko<br></br><span className="txt-small">Project Engineer</span></p></div>
                                            </div>
                                            <div className="col-3 p-1 d-flex justify-content-center" onMouseEnter={() => this.onClick('andreasLeonardi')}>
                                                <div className="namebox d-flex align-items-center justify-content-center text-center"><p className="txt-large p-0 m-0">Andreas Leonardi<br></br><span className="txt-small">Site Engineer</span></p></div>
                                            </div>
                                            <div className="col-3 p-1 d-flex justify-content-center" onMouseEnter={() => this.onClick('binhAnDuong')}>
                                                <div className="namebox d-flex align-items-center justify-content-center text-center"><p className="txt-large p-0 m-0">Binh An Duong<br></br><span className="txt-small">Drafter</span></p></div>
                                            </div>
                                            <div className="col-3 p-1 d-flex justify-content-center" onMouseEnter={() => this.onClick('abhishekChaudhary')}>
                                                <div className="namebox d-flex align-items-center justify-content-center text-center"><p className="txt-large p-0 m-0">Abhishek Chaudhary<br></br><span className="txt-small">Estimator</span></p></div>
                                            </div>
                                        </div>
                                        <div className="row mt-2 mb-5">
                                            <div className="col-6 d-flex justify-content-end p-1" onMouseEnter={() => this.onClick('meandiNiemand')}>
                                                <div className="namebox d-flex align-items-center justify-content-center text-center"><p className="txt-large p-0 m-0">Meandi Niemand<br></br><span className="txt-small m-0 p-0">Admin & Safety Officer</span></p></div>
                                            </div>
                                            <div className="col-6 pb-1 d-flex justify-content-start p-1" onMouseEnter={() => this.onClick('garyDay')}>
                                                <div className="namebox d-flex align-items-center justify-content-center text-center"><p className="txt-large p-0 m-0">Gary Day<br></br><span className="txt-small">Framing Logistics Manager</span></p></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-5 ps-5 p-0 m-0 d-flex">
                                        {anthonyJones &&
                                            <div className="row p-0 m-0 mt-5 align-self-center">
                                                <h3>Anthony Jones – Director</h3>
                                                <p>Anthony is a founding shareholder and director of Anwest. He has been working in the construction industry for the past 13 years on a number of high profile and quality projects. He is our Construction Operations Manager and leads our project teams and site operations.</p>
                                                <p>Anthony is responsible for overseeing the construction process while working closely with site managers and teams to ensure quality and safety standards are achieved while also maintaining communications on the job with the client and project stakeholders.</p>
                                            </div>
                                        }
                                        {nigelJones &&
                                            <div className="row p-0 m-0 align-self-center">
                                                <h3>Nigel Jones – Director</h3>
                                                <p>Nigel is a founding shareholder and director of Anwest. He has considerable industry experience having worked in the WA mining and construction sectors for almost 20 years.  His main focus is the business development, client relationships, financial company growth and overseeing safety protocols.</p>
                                                <p>Nigel is also involved in the guidance of corporate business structures and corporate governance. Nigel is a registered builder and with many experience in both commercial and residential construction.</p>
                                            </div>
                                        }
                                        {laurenEphron &&
                                            <div className="row p-0 m-0 align-self-center">
                                                <h3>Lauren Ephron - Accounts and Administration</h3>
                                                <p>Lauren has over 25 years of experience in bookkeeping with a solid background in accounting and office administration management.  Her main focus is on accounts payable, payroll and all aspects of accounting.  Organising office operations and maintaining procedures ensures the efficient running of the Company.  Lauren oversees the apprenticeships, training and workers compensation claims.</p>
                                            </div>
                                        }
                                        {andreasLeonardi &&
                                            <div className="row p-0 m-0 align-self-center">
                                                <h3>Andreas Leonardi - Site Engineer</h3>
                                                <p>Andreas holds a master’s degree in civil engineering and has worked in structural design in the Telecom industry. His key role includes estimating, procurement, overseeing on-site operations, ensuring adherence to plans and resolving technical challenges to ensure successful project completion.</p>
                                            </div>
                                        }
                                        {simonKirk &&
                                            <div className="row p-0 m-0 align-self-center">
                                                <h3>Simon Kirk - Operations Manager</h3>
                                                <p>Simon is our operations manager with more than 12 years of experience in the WA market.  A ceiling fixer by trade, Simon has run projects with high volume of works simultaneously with 5 to 150 tradesmen on site.  His role is to manage site operations and make sure our work activities are effectively balanced between safety, quality, and programme and to make sure our works are carried out smoothly in conjunction with other trades.</p>
                                            </div>
                                        }
                                        {pabloGarrido &&
                                            <div className="row p-0 m-0 align-self-center">
                                                <h3>Pablo Garrido</h3><h3>Engineering and Estimating Manager</h3>
                                                <p>Pablo has over 14 years of experience in the commercial, mining, and infrastructure sectors having worked in Australia, South America and the Pacific Islands. He has extensive knowledge in managing construction projects from tender stage to completion and hand over. His key roles include Project Management, leading our estimating department, administrating commercial aspects, and providing key technical knowledge for Design and Coordination.</p>
                                                <p>Pablo is a Member of Engineers Australia (MIEAust) holding a Civil Engineering Degree and achieved a PMI Project Management Professional (PMP) certification.</p>
                                            </div>
                                        }
                                        {ellaDudko &&
                                            <div className="row p-0 m-0 align-self-center">
                                                <h3>Ella Dudko - Project Engineer</h3>
                                                <p>Ella holds a Bachelor Degree in Civil Engineering and has over 14 years’ experience in Commercial, Civil and Residential Construction Sectors with main focus on job tendering and making sure a feasibility and constructability analysis have been considered at estimation and construction stage. She carries out Cost and Document control and liaison with Clients and Stakeholders.</p>
                                            </div>
                                        }
                                        {mingYuiCheung &&
                                            <div className="row p-0 m-0 align-self-center">
                                                <h3>Ming Yui Cheung – Design Manager</h3>
                                                <p>Ming has over 10 years experience in Interior Design/Architecture & Drafting overseas with a wide range of design & built projects mainly focused on commercial projects as well as residential, education, retail & hospitality hence all-round knowledge from design stage through to construction stage. She has completed Residential Drafting & Building Design in Perth to enhance her knowledge and skills by combining her hands-on experience in Interior Design/Architecture.</p>
                                                <p>She specialized in providing design solutions for LGS prefabrication framing system, feature ceiling & wall finishes as well as project co-ordination. She oversees drafting team in Anwest to assist in business development by working on R&D, system set up and implementing new workflows for LGS prefabrication framing system & CNC production line.</p>
                                            </div>
                                        }
                                        {binhAnDuong &&
                                            <div className="row p-0 m-0 align-self-center">
                                                <h3>Binh An Duong – Drafter</h3>
                                                <p>Binh An has over 7 years of drafting and design experience.  She has completed a Certificate 4 in Drafting and a Diploma of Building Design.  Binh An has participated in Concept design, specifically in residential and restaurant applications, developing technical projects in detail while working collaboratively in a team environment.  Her primary focus is with light gauge steel (LGS).</p>
                                                <p>Binh An received an Award in the Liveable Homes Student Competition by Central Institute of Technology</p>
                                            </div>
                                        }
                                        {abhishekChaudhary &&
                                            <div className="row p-0 m-0 align-self-center">
                                                <h3>Abhishek Chaudhary – Estimator</h3>
                                                <p>Abhishek has 9 years of experience in cost estimation for diverse construction projects with degree in Civil Engineering in India . Proficient in preparing tender submissions, performing quantity takeoffs, and generating Bill of Quantities (BOQs). Skilled in interpreting construction drawings, sending Requests for Information (RFIs), and ensuring accurate material and labor cost estimates. Adept at maintaining strong relationships with contractors and vendors, and providing cost-saving options. Experienced in both large and small-scale projects, encompassing residential, commercial, and infrastructure sectors.</p>
                                            </div>
                                        }
                                        {meandiNiemand &&
                                            <div className="row p-0 m-0 align-self-center">
                                                <h3>Meandi Niemand – Admin & Safety Officer</h3>
                                                <p>Meandi has 10+ years of administration experience in various industries, her main focus is completing paperwork for OHS requirements, onboarding personnel /equipment to project sites, helping staff who got injured while working through the compensation and rehabilitation process as delineated by Anwest Directors, and managing PPE requirements for workers. She's completing her Cert IV in Health and Safety to develop a management safety plan, review SWMS, and assist in WHS training sessions. She is also responsible for assisting in managing the Inspection Test Plans (ITPs) for contract packages, Administration of all paperwork related to Sample Datasheets, and Administration of the paperwork related to Operation & Maintenance (O&M) Manuals.</p>
                                            </div>
                                        }
                                        {garyDay &&
                                            <div className="row p-0 m-0 align-self-center">
                                                <h3>Gary Day – Framing Logistics Manager</h3>
                                                <p>Gary has nearly 9 years of experience in production and factory management with many of those years spent within the construction industry.</p>
                                                <p>His main responsibility is overseeing production operations at our specialist factory at our head office location.  Gary is in constant contact with both our design and site teams to ensure that production, assembly and delivery of our custom designed wall and ceiling frames is completed within the set timescale</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid headerline d-block d-md-none">
                            <div className="container">
                                <div className="row p-0 m-0 mt-5 align-self-center">
                                    <h3>Anthony Jones – Director</h3>
                                    <p>Anthony is a founding shareholder and director of Anwest. He has been working in the construction industry for the past 13 years on a number of high profile and quality projects. He is our Construction Operations Manager and leads our project teams and site operations.</p>
                                    <p>Anthony is responsible for overseeing the construction process while working closely with site managers and teams to ensure quality and safety standards are achieved while also maintaining communications on the job with the client and project stakeholders.</p>
                                </div>
                                <div className="row p-0 m-0 mt-5 align-self-center">
                                    <h3>Nigel Jones – Director</h3>
                                    <p>Nigel is a founding shareholder and director of Anwest. He has considerable industry experience having worked in the WA mining and construction sectors for almost 20 years.  His main focus is the business development, client relationships, financial company growth and overseeing safety protocols.</p>
                                    <p>Nigel is also involved in the guidance of corporate business structures and corporate governance. Nigel is a registered builder and with many experience in both commercial and residential construction.</p>
                                </div>
                                <div className="row p-0 m-0 mt-5 align-self-center">
                                    <h3>Simon Kirk</h3>
                                    <p>Simon is our site manager with more than 12 years of experience in the WA market.  A ceiling fixer by trade, Simon has run projects with high volume of works simultaneously with 5 to 150 tradesmen on site.  His role is to manage site operations and make sure our work activities are effectively balanced between safety, quality, and programme and to make sure our works are carried out smoothly in conjunction with other trades.</p>
                                </div>
                                <div className="row p-0 m-0 mt-5 align-self-center">
                                    <h3>Pablo Garrido – Project Engineer</h3>
                                    <p>Pablo has over 14 years of experience in the mining, infrastructure and commercial construction sectors, locally and in the Pacific Islands and South America. He has extensive knowledge in managing construction projects from the tender stage to the completion and hand over.  His key roles include Project management, estimating, procurement, contracting, design and coordination, and cost control.</p>
                                    <p>Pablo holds a Bachelor Degree in Civil Engineering and he is a PMI PMP certified professional. </p>
                                </div>
                                <div className="row p-0 m-0 mt-5 align-self-center">
                                    <h3>Ella Dudko</h3>
                                    <p>Ella holds a Bachelor Degree in Civil Engineering and has over 14 years’ experience in Commercial, Civil and Residential Construction Sectors with main focus on job tendering and making sure a feasibility and constructability analysis have been considered at estimation and construction stage. She carries out Cost and Document control and liaison with Clients and Stakeholders.</p>
                                </div>
                                <div className="row p-0 m-0 mt-5 align-self-center">
                                    <h3>Ming Yui Cheung – Design Manager</h3>
                                    <p>Ming has over 10 years experience in Interior Design/Architecture & Drafting overseas with a wide range of design & built projects mainly focused on commercial projects as well as residential, education, retail & hospitality hence all-round knowledge from design stage through to construction stage. She has completed Residential Drafting & Building Design in Perth to enhance her knowledge and skills by combining her hands-on experience in Interior Design/Architecture.</p>
                                    <p>She specialized in providing design solutions for LGS prefabrication framing system, feature ceiling & wall finishes as well as project co-ordination. She oversees drafting team in Anwest to assist in business development by working on R&D, system set up and implementing new workflows for LGS prefabrication framing system & CNC production line.</p>
                                </div>
                                <div className="row p-0 m-0 mt-5 mb-3 align-self-center">
                                    <h3>Lauren Ephron - Accounts and Administration</h3>
                                    <p>Lauren has over 25 years of experience in bookkeeping with a solid background in accounting and office administration management.  Her main focus is on accounts payable, payroll and all aspects of accounting.  Organising office operations and maintaining procedures ensures the efficient running of the Company.  Lauren oversees the apprenticeships, training and workers compensation claims.</p>
                                </div>
                                <div className="row p-0 m-0 mt-5 align-self-center">
                                    <h3>Binh An Duong – Drafter</h3>
                                    <p>Binh An has over 7 years of drafting and design experience.  She has completed a Certificate 4 in Drafting and a Diploma of Building Design.  Binh An has participated in Concept design, specifically in residential and restaurant applications, developing technical projects in detail while working collaboratively in a team environment.  Her primary focus is with light gauge steel (LGS).</p>
                                    <p>Binh An received an Award in the Liveable Homes Student Competition by Central Institute of Technology</p>
                                </div>
                                <div className="row p-0 m-0 mt-5 align-self-center">
                                    <h3>Abhishek Chaudhary – Estimator</h3>
                                    <p>Abhishek has 9 years of experience in cost estimation for diverse construction projects with degree in Civil Engineering in India . Proficient in preparing tender submissions, performing quantity takeoffs, and generating Bill of Quantities (BOQs). Skilled in interpreting construction drawings, sending Requests for Information (RFIs), and ensuring accurate material and labor cost estimates. Adept at maintaining strong relationships with contractors and vendors, and providing cost-saving options. Experienced in both large and small-scale projects, encompassing residential, commercial, and infrastructure sectors.</p>
                                </div>
                                <div className="row p-0 m-0 mt-5 align-self-center">
                                    <h3>Andreas Leonardi - Site Engineer</h3>
                                    <p>Andreas holds a master’s degree in civil engineering and has worked in structural design in the Telecom industry. His key role includes estimating, procurement, overseeing on-site operations, ensuring adherence to plans and resolving technical challenges to ensure successful project completion.</p>
                                </div>
                                <div className="row p-0 m-0 mt-5 align-self-center">
                                    <h3>Meandi Niemand – Admin & Safety Officer</h3>
                                    <p>Meandi has 10+ years of administration experience in various industries, her main focus is completing paperwork for OHS requirements, onboarding personnel /equipment to project sites, helping staff who got injured while working through the compensation and rehabilitation process as delineated by Anwest Directors, and managing PPE requirements for workers. She's completing her Cert IV in Health and Safety to develop a management safety plan, review SWMS, and assist in WHS training sessions. She is also responsible for assisting in managing the Inspection Test Plans (ITPs) for contract packages, Administration of all paperwork related to Sample Datasheets, and Administration of the paperwork related to Operation & Maintenance (O&M) Manuals.</p>
                                </div>
                                <div className="row p-0 m-0 mt-5 align-self-center">
                                    <h3>Gary Day – Framing Logistics Manager</h3>
                                    <p>Gary has nearly 9 years of experience in production and factory management with many of those years spent within the construction industry.</p>
                                    <p>His main responsibility is overseeing production operations at our specialist factory at our head office location.  Gary is in constant contact with both our design and site teams to ensure that production, assembly and delivery of our custom designed wall and ceiling frames is completed within the set timescale</p>
                                </div>

                            </div>
                        </div>
                        <Footer />
                    </div>
                }
            </div >
        );
    }
}

export default Team;
